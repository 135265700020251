




























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import HeaderImage from './components/HeaderImage.vue'
import Navbar from './components/Navbar.vue'
import PageFooter from './components/PageFooter.vue'
import Contacts from './components/Contacts.vue'

@Component({ components: { HeaderImage, Navbar, PageFooter, Contacts } })
export default class App extends Vue {}
